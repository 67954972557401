import React, { useState, useContext } from "react"
import { navigate } from "gatsby"

import CartContext from "../../context/cart/cart-context"
import CheckoutWrapper, {
  CheckoutSplitStyling,
  RightSideSection,
} from "../../components/checkout/checkout-wrapper"
import ShippingAddress from "../../components/account/address/shipping-address"
import ShippingOptions from "../../components/checkout/shipping-options"
import OrderSummary from "../../components/checkout/order-summary"
import ContinueButton from "../../components/checkout/continue-button"
import BillingAddress from "../../components/account/address/billing-address"

const Delivery = ({ location }) => {
  const { cartMeta } = useContext(CartContext)
  // This is a reference to the ShippingAddress component so we can trigger account save
  const [submitBilling, setSubmitBilling] = useState(false)
  const [submitShipping, setSubmitShipping] = useState(false)
  const [shippingDataUpdated, setShippingDataUpdated] = useState(false)
  const [updatedShippingData, setUpdatedShippingData] = useState(null)

  // Trigger a continue to the payment page
  const continueToNextTab = async () => {
    navigate("/checkout/payment/")
  }

  // Handler for clicking on the continue button
  const continueShipping = () => {
    setSubmitShipping(true)
    // If we don't want to force address submissions, just run continueToNextTab() here
  }

  const continueBilling = () => {
    setSubmitBilling(true)
  }

  return (
    <CheckoutWrapper checkoutStep="delivery" location={location}>
      <CheckoutSplitStyling>
        <div>
          <ShippingOptions />
          <BillingAddress
            continueCallback={continueToNextTab}
            tryToSubmitAccount={submitBilling}
            setTryToSubmitAccount={setSubmitBilling}
            showPersonalInfo={false}
            onPage={
              cartMeta?.selected_shipping_method?.key != "local_pickup:7"
                ? "checkout"
                : ""
            }
            onCheckout={true}
            updatedShippingData={updatedShippingData}
            shippingDataUpdated={shippingDataUpdated}
          />
          {cartMeta?.selected_shipping_method?.key != "local_pickup:7" ? (
            <ShippingAddress
              continueCallback={continueBilling}
              tryToSubmitAccount={submitShipping}
              setTryToSubmitAccount={setSubmitShipping}
              showPersonalInfo={false}
              setUpdatedShippingData={setUpdatedShippingData}
              setShippingDataUpdated={setShippingDataUpdated}
            />
          ) : null}
        </div>
        <RightSideSection>
          <OrderSummary showShipping={false} />
          <ContinueButton
            callback={
              cartMeta?.selected_shipping_method?.key != "local_pickup:7"
                ? continueShipping
                : continueBilling
            }
          />
        </RightSideSection>
      </CheckoutSplitStyling>
    </CheckoutWrapper>
  )
}

export default Delivery
