import React, { useState, useEffect, useContext, useRef } from "react"
import styled from "styled-components"

import AuthContext from "../../../context/auth/auth-context"
import CartContext from "../../../context/cart/cart-context"

import { Formik, Form, Field, ErrorMessage, getIn } from "formik"

import Personal from "./personal"
import BlurLoader from "../../reusable-components/loaders/blur-loader"

import {
  getCustomerAddress,
  getCountries,
  updateCustomerAddress,
} from "../../../api/account"
import { getUser } from "../../../context/auth/auth-functions"
import AccountMetaFields from "./account-meta-fields"
import BillingAddressFields from "./billing-address-fields"
import { analyticsUpdateAccount } from "../../../services/google-analytics"
import { pixelUpdateAccount } from "../../../services/facebook-pixel"
import { windowWidths } from "../../theme/theme-config"

// ======================
//       COMPONENT
// ======================

const BillingAddress = ({
  givenAccountData = {},
  onPage = "",
  continueCallback = () => {},
  setReadyToSubmitAccount = () => {},
  setTryToSubmitAccount = () => {},
  tryToSubmitAccount = false,
  condense,
  showPersonalInfo = true,
  updatedShippingData = null,
  shippingDataUpdated = false,
}) => {
  const [loading, setLoading] = useState(true)
  const [changesSaved, setChangesSaved] = useState(false)
  const [countriesLoading, setCountriesLoading] = useState(true)
  const [apiError, setApiError] = useState("")
  const [submitError, setSubmitError] = useState("")
  const [provinceOptions, setProvinceOptions] = useState(false)
  const [accountData, setAccountData] = useState(givenAccountData)
  const [googleSearchText, setGoogleSearchText] = useState("")
  const [showManualAddressButton, setShowManualAddressButton] = useState(false)
  const [billingAsShipping, setBillingAsShipping] = useState(false)
  const [disableForm, setDisableForm] = useState(false)

  const formikRef = useRef(null)

  useEffect(() => {
    if (billingAsShipping && updatedShippingData) {
      setAccountData((prevState) => {
        prevState.shipping = updatedShippingData.shipping
        return prevState
      })
      formikRef.current?.resetForm()
    }
    if (billingAsShipping === true) {
      setDisableForm(true)
    } else {
      setDisableForm(false)
    }
  }, [billingAsShipping, shippingDataUpdated])

  const { loggedInState, performLogout } = useContext(AuthContext)
  const { getCart } = useContext(CartContext)

  useEffect(() => {
    if (googleSearchText && !showManualAddressButton) {
      setShowManualAddressButton(true)
    }
  }, [googleSearchText])

  // Fetch address from WC
  useEffect(() => {
    if (givenAccountData.id) {
      setLoading(false)
      return
    }
    const { token } = getUser()
    refreshCountryAndProvince(token)
    fetchAddress(token)
    if (billingAsShipping) {
      setDisableForm(false)
    } else {
      setDisableForm(true)
    }
    if (billingAsShipping === true) {
      setDisableForm(true)
    } else {
      setDisableForm(false)
    }
  }, [])

  // Fetch Address from WooCommerce
  const fetchAddress = (token) => {
    getCustomerAddress(token, loggedInState)
      .then(async (resp) => {
        const body = await resp
        setAccountData(body)
        setBillingAsShipping(body.billing_same_as_shipping ? true : false)
        setLoading(false)
      })
      .catch(async (error) => {
        let errorString = "Failed to fetch customer info"
        if (error.message) {
          errorString = error.message
        }
        setApiError(errorString)
        setLoading(false)
      })
  }

  const refreshCountryAndProvince = (token) => {
    setCountriesLoading(true)
    Promise.all([
      getCountries(token, performLogout, "ZA").then((provinceData) => {
        setProvinceOptions(provinceData)
      }),
    ])
      .then(() => {
        setCountriesLoading(false)
      })
      .catch(() => {
        setCountriesLoading(false)
      })
  }

  // Province list refreshed
  useEffect(() => {
    if (provinceOptions && provinceOptions.length > 0) {
      if (accountData.billing) {
        if (!provinceOptions[accountData.billing.state]) {
          // Update values structure
          setAccountData((prev) => {
            let newState = Object.assign({}, prev)
            newState["billing"]["state"] = Object.keys(provinceOptions)[0]
            return newState
          })
        }
      }
    }
  }, [provinceOptions])

  // Handle Account info submission
  const submitAddress = (values) => {
    setLoading(true)
    const { token } = getUser()

    // Map values to WooCommerce structure
    let [tempName, ...tempSurname] =
      values.shipping_name && values.shipping_name.split(" ")
    tempSurname = tempSurname.join(" ") || " "

    let screenSize = 0
    if (
      typeof window == "object" &&
      typeof window.screen == "object" &&
      typeof window.screen.width != "undefined"
    ) {
      screenSize = window.screen.width
    }
    let device = "Desktop"
    if (screenSize < windowWidths.tabletSmall) {
      device = "Mobile"
    } else if (screenSize < windowWidths.desktopSmall) {
      device = "Tablet"
    }

    const tmpAddress = {
      type: "billing",
      billing_same_as_shipping: billingAsShipping,
      first_name: values.first_name,
      last_name: values.last_name,
      vat_number: values.vat_number,
      discount_code: values.discount_code,
      address: {
        first_name: tempName,
        last_name: tempSurname,
        phone: values.phone,
        company: values.company,
        address_1: values.address_1,
        address_2: values.address_2,
        city: values.city,
        postcode: values.postcode,
        state: values.state,
        country: values.country,
      },
    }
    // We allow user to leave out address_2 but WooCommerce requires a value
    if (!tmpAddress.address.address_2) {
      tmpAddress.address.address_2 = "-"
    }
    console.log("Sending new address: ", tmpAddress)
    /* Update customer address */
    updateCustomerAddress(tmpAddress, token, performLogout)
      .then((res) => {
        if (res.billing) {
          setAccountData(res)
          setChangesSaved(true)
          analyticsUpdateAccount()
          pixelUpdateAccount()
          setApiError(false)
          setLoading(false)
          getCart()
          if (typeof continueCallback === "function") continueCallback(true)
        } else {
          // eslint-disable-next-line no-throw-literal
          throw "Bad response"
        }
      })
      .catch((error) => {
        console.log(error)
        setApiError(true)
        fetchAddress()
      })
  }

  // Render options dropdown with all provinces
  const ProvinceOptions = () => {
    return (
      <>
        <option key={""} value={""}>
          {"Select the delivery Province"}
        </option>
        {Object.entries(provinceOptions).map((province) => {
          return (
            <option key={province[0]} value={province[0]}>
              {province[1]}
            </option>
          )
        })}
      </>
    )
  }

  // If error, show error
  if (apiError) {
    return (
      <>
        <p>The following error occured while fetching your Account Info:</p>
        <p>{apiError}</p>
      </>
    )
  }

  return (
    <BlurLoader loading={loading} message="Updating">
      {!submitError ? null : (
        <SubmitErrorStyling>{submitError}</SubmitErrorStyling>
      )}
      <Formik
        innerRef={formikRef}
        initialValues={{
          first_name: accountData.first_name || "",
          last_name: accountData.last_name || "",
          shipping_name: billingAsShipping
            ? (accountData.shipping &&
              accountData.shipping.first_name &&
              accountData.shipping.last_name != " "
                ? `${accountData.shipping.first_name} ${accountData.shipping.last_name}`
                : accountData.shipping && accountData.shipping.first_name) || ""
            : (accountData.billing &&
              accountData.billing.first_name &&
              accountData.billing.last_name != " "
                ? `${accountData.billing.first_name} ${accountData.billing.last_name}`
                : accountData.billing && accountData.billing.first_name) || "",
          company: (accountData.billing && accountData.billing.company) || "",
          vat_number: accountData.vat_number || "",
          discount_code: accountData.discount_code || "",
          address_1: billingAsShipping
            ? (accountData.shipping && accountData.shipping.address_1) || ""
            : (accountData.billing && accountData.billing.address_1) || "",
          address_2: billingAsShipping
            ? (accountData.shipping && accountData.shipping.address_2) || ""
            : (accountData.billing && accountData.billing.address_2) || "",
          city: billingAsShipping
            ? (accountData.shipping && accountData.shipping.city) || ""
            : (accountData.billing && accountData.billing.city) || "",
          postcode: billingAsShipping
            ? (accountData.shipping && accountData.shipping.postcode) || ""
            : (accountData.billing && accountData.billing.postcode) || "",
          state: billingAsShipping
            ? (accountData.shipping && accountData.shipping.state) || ""
            : (accountData.billing && accountData.billing.state) || "",
          country: billingAsShipping
            ? (accountData.shipping && accountData.shipping.country) || "ZA"
            : (accountData.billing && accountData.billing.country) || "ZA",
        }}
        enableReinitialize={true}
        validate={(values) => validate(values, onPage)}
        onSubmit={submitAddress}
        disabled
      >
        {({
          errors,
          touched,
          values,
          validateForm,
          submitForm,
          setFieldValue,
        }) => {
          if (
            typeof values == "object" &&
            typeof values.google_address_search == "string" &&
            values.google_address_search != googleSearchText
          ) {
            setGoogleSearchText(values.google_address_search)
          }
          const [googleAutocompletePopulated, setGoogleAutocompletePopulated] =
            useState(false)
          // Refresh field values

          useEffect(() => {
            if (googleAutocompletePopulated) {
              validateForm()
            }
          }, [googleAutocompletePopulated])

          // Try to submit account
          useEffect(() => {
            if (tryToSubmitAccount) {
              setSubmitError("")
              validateForm()
                .then((res) => {
                  if (
                    submitOnClick() &&
                    !loading &&
                    !countriesLoading &&
                    Object.keys(res)?.length === 0
                  ) {
                    submitForm()
                  } else if (!loading) {
                    setSubmitError("Please complete all fields below.")
                  }
                })
                .catch(submitOnClick)
              setTryToSubmitAccount(false)
            }
          }, [tryToSubmitAccount])

          // Try to submit account
          useEffect(() => {
            if (loading || countriesLoading) {
              setReadyToSubmitAccount(false)
            } else {
              setReadyToSubmitAccount(true)
            }
          }, [loading, countriesLoading])

          const submitOnClick = () => {
            return true
          }

          return (
            <StyledForm
              id="account-form"
              className={
                onPage == "checkout" && billingAsShipping ? "collapsed" : ""
              }
            >
              <FormStyling
                showPersonalInfo={showPersonalInfo}
                className={condense && "condense"}
              >
                {!showPersonalInfo ? null : (
                  <LeftPanelStyling className="left">
                    <StyledH3>Personal Information</StyledH3>
                    <Personal errors={errors} touched={touched} />
                    <AccountMetaFields />
                  </LeftPanelStyling>
                )}
                <RightPanelStyling>
                  <StyledH3 style={{ marginBottom: "0" }}>
                    Billing Address
                    <span>
                      <p style={{ marginTop: "0", marginBottom: "0" }}>
                        Same as shipping address
                        <input
                          type="checkbox"
                          id="billingAsShipping"
                          name="billingAsShipping"
                          value="billingAsShipping"
                          checked={billingAsShipping}
                          onChange={() =>
                            setBillingAsShipping((prevState) => !prevState)
                          }
                        />
                      </p>
                    </span>
                  </StyledH3>
                  <BillingAddressFields
                    errors={errors}
                    touched={touched}
                    disabled={disableForm}
                  >
                    {Object.keys(provinceOptions).length > 0 && (
                      <ProvinceDropdownStyling light className="select">
                        <label htmlFor="state">Province:</label>
                        <Field
                          as="select"
                          disabled={countriesLoading || disableForm}
                          id="state"
                          type="text"
                          name="state"
                          autoComplete="shipping address-level1"
                          style={getStyles(errors, touched, "state")}
                        >
                          <ProvinceOptions values={values} />
                        </Field>
                        <ErrorMessage
                          name="state"
                          render={(msg) => <div className="error">{msg}</div>}
                          className="error"
                        />
                      </ProvinceDropdownStyling>
                    )}
                  </BillingAddressFields>
                </RightPanelStyling>
              </FormStyling>
              {onPage !== "checkout" && (
                <VerticalPaddingStyling>
                  <ButtonRowStyling>
                    <button
                      type="submit"
                      disabled={loading || countriesLoading}
                      onClick={submitOnClick}
                    >
                      SAVE CHANGES
                    </button>
                  </ButtonRowStyling>
                </VerticalPaddingStyling>
              )}
            </StyledForm>
          )
        }}
      </Formik>
    </BlurLoader>
  )
}

// ======================
// 	🔧🔧 HELPERS 🔧🔧
// ======================
function getStyles(errors, touched, fieldName) {
  if (getIn(errors, fieldName) && getIn(touched, fieldName)) {
    return {
      border: "1px solid red",
    }
  }
}

const validate = (values, onPage) => {
  const errors = {}

  // on checkout, the personal details aren't shown so don't check them
  if (onPage !== "checkout" && !values.first_name) {
    errors.first_name = "Your First Name is required"
  }
  if (onPage !== "checkout" && !values.last_name) {
    errors.last_name = "Your Last Name is required"
  }

  if (!values.shipping_name) {
    errors.shipping_name = "Name on card is requried"
  }
  if (!values.shipping_name) {
    errors.name = "Delivery Contact Name is required"
  }
  if (!values.address_1) {
    errors.address_1 = "Street Address is required"
  }
  if (!values.city) {
    errors.city = "City is required"
  }
  if (!values.state) {
    errors.state = "Province is required"
  }
  if (!values.country) {
    errors.country = "Country is required"
  }
  if (!values.postcode) {
    errors.postcode = "Postal code is required"
  } else if (!/^[0-9]{4}$/.test(values.postcode)) {
    errors.postcode = "Please use a 4 digit postal code"
  }
  return errors
}

// ======================
//         STYLES
// ======================

const SubmitErrorStyling = styled.div`
  color: red;
`

const StyledForm = styled(Form)`
  max-height: 1200px;
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;

  &.collapsed {
    max-height: 70px;
  }
`

const ButtonRowStyling = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: ${(props) => (props.align ? props.align : "center")};
`

const ProvinceDropdownStyling = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0px 0px 15px 0px;

  select {
    padding: 15px;
    border-radius: 4px;
    background: #f6f6f6;
    border: 1px solid #cfd5db;
  }

  div.error {
    margin: 10px 0;
    color: black;
  }

  span.icon {
    position: absolute;
    top: 57px;
    right: 5px;
    cursor: pointer;
  }

  label {
    display: flex;
    color: ${({ light }) => (light ? "#223247" : "#f0f1f3")};
    font-family: Roboto;
    font-size: 16px;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      color: red;
    }
    span {
      color: #fd3237;
      text-align: right;
    }
  }

  input,
  select {
    border: 1px solid transparent;
    border-radius: 2px;
    outline: none;
    border-right-width: 20px;
    background-color: ${({ theme }) => theme.colors.lightgrey2};
    padding: 15px;
    color: ${({ light }) => (light ? "color: #223247" : "white")};
  }
`

const FormStyling = styled.div`
  display: grid;
  grid-template-columns: ${({ showPersonalInfo }) =>
    showPersonalInfo ? "2fr 3fr;" : "1fr;"};
  grid-gap: 40px;

  div.error {
    margin: 10px 0;
    color: red;
  }

  &.condense {
    display: block;
    .left {
      display: none;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    grid-template-columns: ${({ showPersonalInfo }) =>
      showPersonalInfo ? "1fr 1fr;" : "1fr;"};
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    grid-template-columns: 1fr;
  }
`

const VerticalPaddingStyling = styled.div`
  padding: 20px 0;
`
const LeftPanelStyling = styled.div``
const StyledH3 = styled.h3`
  font-weight: 600;
  margin-bottom: 30px;

  p {
    display: flex;
    align-items: center;
  }

  input {
    margin-left: 10px;
    width: 15px;
    height: 15px;
  }
`
const RightPanelStyling = styled.div``

export default BillingAddress
