import React from "react"
import AccountsFormInputField from "../accounts-form-input-field"

// ======================
// 	     COMPONENT
// ======================

const Personal = ({ errors, touched }) => {
  return (
    <>
      <AccountsFormInputField
        light
        label="First Name: "
        id="first_name"
        name="first_name"
        type="text"
        autoComplete="given-name"
        placeholder="Your Name"
        size="50"
        key="first_name"
        i={0}
        errors={errors}
        touched={touched}
      />
      <AccountsFormInputField
        light
        label="Last Name: "
        id="last_name"
        name="last_name"
        type="text"
        autoComplete="family-name"
        placeholder="Your Surname"
        size="50"
        key="last_name"
        i={1}
        errors={errors}
        touched={touched}
      />
    </>
  )
}

export default Personal
