import React from "react"

function UncheckedSquare({ className = "" }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
    >
      <rect
        width="14"
        height="14"
        fill="none"
        stroke="#fff"
        strokeWidth="1"
        data-name="unchecked box"
        rx="2"
        transform="translate(.5 .5)"
      ></rect>
    </svg>
  )
}

export default UncheckedSquare
