import React from "react"
import styled from "styled-components"

import AccountsFormInputField from "../accounts-form-input-field"

// ======================
// 	     COMPONENT
// ======================

const BillingAddressFields = ({
  children,
  pinLatLng = false,
  showManualAddressSection = false,
  errors,
  touched,
  disabled,
}) => {
  return (
    <div>
      <AccountsFormInputField
        light
        label="Name on card"
        id="shipping_name"
        name="shipping_name"
        type="text"
        autoComplete="name"
        placeholder="Name and Surname"
        size="50"
        i={3}
        errors={errors}
        touched={touched}
        disabled={disabled}
      />
      <AddressInputsStyling>
        <AccountsFormInputField
          light
          label="Street Address: "
          id="address_1"
          name="address_1"
          type="text"
          placeholder="Street address"
          autoComplete="shipping street-address address-line1"
          size="50"
          i={6}
          errors={errors}
          touched={touched}
          disabled={disabled}
        />
        <AccountsFormInputField
          light
          label="Suburb (Optional): "
          id="address_2"
          name="address_2"
          type="text"
          autoComplete="shipping street-address address-line2"
          placeholder="Suburb"
          size="50"
          i={8}
          errors={errors}
          touched={touched}
          disabled={disabled}
        />
        <AccountsFormInputField
          light
          label="City / Town: "
          id="city"
          name="city"
          type="text"
          autoComplete="shipping address-level2"
          placeholder="City"
          size="50"
          i={9}
          errors={errors}
          touched={touched}
          disabled={disabled}
        />
        <AccountsFormInputField
          light
          label="Postal Code: "
          id="postcode"
          name="postcode"
          type="text"
          autoComplete="shipping postal-code"
          placeholder="0000"
          size="50"
          i={10}
          errors={errors}
          touched={touched}
          disabled={disabled}
        />
        {children}
      </AddressInputsStyling>
    </div>
  )
}

export default BillingAddressFields

// ======================
//        STYLES
// ======================
const AddressInputsStyling = styled.div``
