import React from "react"
import styled from "styled-components"

import AccountsFormInputField from "../accounts-form-input-field"

// ======================
// 	     COMPONENT
// ======================
const AccountMetaFields = ({ errors, touched }) => {
  return (
    <>
      <StyledH3>Company Details</StyledH3>
      <AccountsFormInputField
        label="Company name"
        type="text"
        name="company"
        autoComplete="organization"
        placeholder="Company name"
        errors={errors}
        touched={touched}
      />
      <AccountsFormInputField
        label="VAT number (optional)"
        type="text"
        name="vat_number"
        placeholder="VAT number (optional)"
        errors={errors}
        touched={touched}
      />
      <AccountsFormInputField
        label="Discount code (optional)"
        type="text"
        name="discount_code"
        placeholder="Discount code (optional)"
        errors={errors}
        touched={touched}
      />
    </>
  )
}

export default AccountMetaFields

// ======================
//        STYLES
// ======================
const StyledH3 = styled.h3`
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 30px;
`
