import React from "react"

const CheckSquareLarge = ({ className = "" }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="15.931"
    height="14.949"
    viewBox="0 0 15.931 14.949"
  >
    <g
      id="Icon_feather-check-square"
      data-name="Icon feather-check-square"
      transform="translate(-4 -4)"
    >
      <path
        id="Path_779"
        d="M13.5,11.424l2.325,2.325L23.574,6"
        transform="translate(-4.35 -0.725)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Path_780"
        d="M18.449,11.474V16.9a1.55,1.55,0,0,1-1.55,1.55H6.05A1.55,1.55,0,0,1,4.5,16.9V6.05A1.55,1.55,0,0,1,6.05,4.5h8.524"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </g>
  </svg>
)

export default CheckSquareLarge
